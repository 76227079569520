import React from 'react'
import { withTheme } from 'emotion-theming'
import HeaderStyles from './Header.styles'
import SplitContentStyles from './SplitContent.styles'
import Banner from './Banner.styles'
import Carousel from './Carousel.styles'
import Columns from './Columns.styles'
import HeroStyles from './Hero.styles'
import FooterStyles from './Footer.styles'
import GlobalCss from './GlobalCss.styles'
import NarrowBanner from './NarrowBanner.styles'
import Variable from './Variable.styles'
import Forms from './Forms.styles'
import Table from './Table.styles'
import Background from './Background.styles'

const GlobalStyles = withTheme(() => (
  <>
    <HeaderStyles />
    <GlobalCss />
    <Forms />
    <Variable />
    <HeroStyles />
    <Carousel />
    <SplitContentStyles />
    <NarrowBanner />
    <Banner />
    <Columns />
    <FooterStyles />
    <Table />
    <Background />
  </>
))

export default GlobalStyles
