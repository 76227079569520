import * as React from 'react'
import { withTheme } from 'emotion-theming'
import { css, Global } from '@emotion/core'

const Forms = withTheme(() => (
  <Global
    styles={css`
      .leshen-form-submission-message {
        text-align: center;
      }

      .sms-form-thank-you {
        text-align: center;
        display: block;
        padding-bottom: 40px;
      }

      .policy-check-form {
        .group {
          display: block;
        }

        .leshen-form {
          margin: 0 auto;
          max-width: 300px;

          @media (max-width: 540px) {
            max-width: 100%;
          }

          input {
            border-radius: 4px;
            border: none;

            @media (max-width: 540px) {
              width: 100% !important;
            }
          }

          button {
            border-radius: 32px !important;
            width: 100%;
            background-color: #1990ff;
            color: #fff;
            border: none;
            padding: 15px 20px;
            font-size: 24px;
          }
        }
      }

      .sms-form {
        .leshen-brandy {
          font-size: 14px;
        }
      }
    `}
  />
))

export default Forms
