import * as React from 'react'
import { withTheme } from 'emotion-theming'
import { css, Global } from '@emotion/core'

const SplitContentCss = withTheme(() => (
  <Global
    styles={css`
      .leshen-split-content {
        .split-content__content {
          @media (max-width: 540px) {
            .text-align-center {
              text-align: center !important;
            }
          }
        }
      }
      .star-ratings {
        margin-top: 50px;
        margin-bottom: 20px;

        @media (max-width: 540px) {
          margin: 0 auto 20px;
        }
      }

      .ssl-licence-link {
        display: flex;
        align-items: center;

        @media (max-width: 540px) {
          justify-content: center;
        }

        a {
          font-size: 18px;
          color: #1990ff !important;
        }

        .gatsby-image-wrapper {
          margin-right: 20px;
        }
      }

      .hero-split-content {
        .image-split {
          overflow: hidden;

          img {
            @media (min-width: 940px) {
              height: 100% !important;
              object-position: top !important;
          }
        }

        @media (max-width: 540px) {
          .content {
            text-align: center;
          }
        }
      }

      .leshen-billboard-section-split {
        .leshen-link-button {
          min-width: 400px;

          @media (max-width: 540px) {
            width: 100%;
            min-width: unset;
          }
        }
      }

      .faq-hero-split {
        padding-top: 120px !important;
        padding-bottom: 120px !important;

        @media (max-width: 540px) {
          padding-top: 30px !important;
          padding-bottom: 0px !important;
        }
      }

      .reverse-desktop {
        .split-content__grid {
          @media (min-width: 767px) {
            grid-template-areas: 'image content' !important;
          }
        }
    `}
  />
))

export default SplitContentCss
