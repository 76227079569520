import * as React from 'react'
import { withTheme } from 'emotion-theming'
import { css, Global } from '@emotion/core'
import triangleBackground from '../../images/backgrounds/purple-triangle-background.jpg'
import purpleTriangleEdges from '../../images/backgrounds/purple-triangle-edges.jpg'
import dottedBottom from '../../images/backgrounds/dotted-bottom.jpg'
import triangleBackgroundNoDots from '../../images/backgrounds/purple-tri-no-dots.jpg'
import blueBackgroundDots from '../../images/backgrounds/blue-with-dots-desktop.jpg'
import blueBackgroundDotsMobile from '../../images/backgrounds/blue-with-dots-mobile.jpg'
import blueBackgroundPinkLines from '../../images/backgrounds/blue-with-pink-lines.jpg'
import blueBackgroundPinkLinesMobile from '../../images/backgrounds/blue-with-pink-lines-mobile.jpg'

const HeroStyles = withTheme(({ theme }) => (
  <Global
    styles={css`
      .dotted-purple-background {
        background-size: cover !important;
        background-position: center;
      }

      .purple-triangle-background {
        background-image: url(${triangleBackground});
        background-size: cover;
        color: #fff;

        section {
          padding-bottom: 0;
        }
      }

      .purple-tri-background-no-dots {
        background-image: url(${triangleBackgroundNoDots});
        background-size: cover;
        color: #fff;
      }

      .purple-triangle-edge-background {
        background-image: url(${purpleTriangleEdges});
        background-position: bottom;

        @media (max-width: 540px) {
          background-image: none;
          background-color: #02168c;
        }
      }

      .policy-tracker {
        padding-bottom: 130px !important;
        background-image: url(${dottedBottom});
        background-size: cover;
        background-position: bottom;

        @media (max-width: 768px) {
          background-image: none;
          padding-bottom: 0 !important;
        }
      }

      .blue-dots-background {
        background-image: url(${blueBackgroundDotsMobile});
        background-size: cover;
        color: #fff;

        @media screen and (min-width: ${theme.breakpoints.sm}) {
          background-image: url(${blueBackgroundDots});
        }
      }

      .blue-pink-lines-background {
        background-image: url(${blueBackgroundPinkLinesMobile});
        background-size: cover;
        background-position: bottom;
        color: #fff;

        @media screen and (min-width: ${theme.breakpoints.sm}) {
          background-image: url(${blueBackgroundPinkLines});
        }
      }
    `}
  />
))

export default HeroStyles
