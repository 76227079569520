import * as React from 'react'
import { withTheme } from 'emotion-theming'
import { css, Global } from '@emotion/core'

const HeroStyles = withTheme(() => (
  <Global
    styles={css`
      .quote-slide-carousel {
        .leshen-carousel {
          .button {
            box-shadow: none;

            svg {
              fill: #02168b;
            }
          }
        }
      }

      .quote-slide {
        padding-bottom: 0 !important;
        padding-top: 0 !important;
      }

      .hide-nav-button {
        .button {
          &.next,
          &.previous {
            display: none;
          }
        }
      }

      .button {
        &.next {
          left: 95%;
        }

        &.previous {
          right: 95%;
        }
      }
    `}
  />
))

export default HeroStyles
