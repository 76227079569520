import * as React from 'react'
import { withTheme } from 'emotion-theming'
import { css, Global } from '@emotion/core'

const FooterStyles = withTheme(() => (
  <Global
    styles={css`
      .leshen-footer {
        color: #929aa5 !important;

        .top-section {
          padding-bottom: 30px;
          padding-top: 30px;
          border-bottom: none;

          @media (max-width: 540px) {
            justify-content: center;
            padding-bottom: 15px;
          }
        }

        .bottom-section {
          padding-top: 0;
          padding-bottom: 0;
          background-color: #fff;

          .nav {
            p {
              font-weight: 700;
            }

            a {
              display: inline-block;
              color: #929aa5 !important;
            }
          }
        }

        .legal-section {
          color: #929aa5 !important;
        }
      }
    `}
  />
))

export default FooterStyles
