import * as React from 'react'
import { withTheme } from 'emotion-theming'
import { css, Global } from '@emotion/core'

const Columns = withTheme(() => (
  <Global
    styles={css`
      .purple-img-background {
        background-image: url('../../images/backgrounds/purple-img-background.jpg);
        color: #fff;
      }

      .product-icons {
        margin: 0 auto;

        &.left-aligned {
          margin: 0 auto 0 0;
        }
      }

      .centered-flex-columns {
        min-width: 100%;
        height: 100%;

        @media screen and (max-width: 939px) {
          margin: 0 16px;
          min-width: 95%;
          max-width: 95%;
        }

        .content {
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
        }
      }
    `}
  />
))

export default Columns
