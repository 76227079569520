import * as React from 'react'
import { withTheme } from 'emotion-theming'
import { css, Global } from '@emotion/core'

const HeaderStyles = withTheme(() => (
  <Global
    styles={css`
      .hero-split-content {
        .leshen-link-button {
          margin-bottom: 25px;

          @media (max-width: 540px) {
            margin: 0 auto 25px;
          }
        }

        .leshen-billboard {
          @media (max-width: 540px) {
            padding-bottom: 0;
          }
        }
      }
    `}
  />
))

export default HeaderStyles
