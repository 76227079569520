import * as React from 'react'
import { withTheme } from 'emotion-theming'
import { css, Global } from '@emotion/core'

const NarrowBanner = withTheme(() => (
  <Global
    styles={css`
      .client-portal-banner {
        .leshen-link {
          button {
            padding: 16px 60px;
            span {
              font-size: 24px !important;
            }
          }
        }
      }

      .dual-button-banner {
        .desktop-text {
          @media (max-width: 540px) {
            display: none;
          }
        }

        .mobile-text {
          display: none;

          @media (max-width: 540px) {
            display: block;
          }
        }

        .leshen-link {
          button {
            padding: 16px 30px;

            span {
              font-size: 24px;
            }
          }

          @media (max-width: 768px) {
            width: 100%;

            .leshen-link-button {
              width: 100%;
            }
          }
        }
      }
    `}
  />
))

export default NarrowBanner
