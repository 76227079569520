import * as React from 'react'
import { withTheme } from 'emotion-theming'
import { css, Global } from '@emotion/core'

const Variable = withTheme(() => (
  <Global
    styles={css`
      .policy-form {
        text-align: center;

        .content {
          margin-bottom: 30px;

          @media (max-width: 540px) {
            margin-bottom: 15px;
          }
        }

        input {
          padding: 15px 10px;
          width: 300px;
          display: block;
          margin: 0 auto;

          @media (max-width: 540px) {
            width: 100%;
          }
        }

        .leshen-link {
          button {
            margin: 20px auto;
            width: 300px;
            padding: 15px 10px;

            @media (max-width: 540px) {
              width: 100%;
            }
          }
        }
      }

      .policy-tracker {
        .desktop {
          padding-bottom: 0;
        }

        @media (max-width: 540px) {
          padding: 0 20px;
        }

        .content {
          width: 100%;
          margin-bottom: 30px;
        }

        .desktop {
          @media (max-width: 768px) {
            display: none;
          }
        }

        .mobile {
          display: none;
          @media (max-width: 768px) {
            display: block;
            padding: 20px 0 !important;
          }
        }
      }

      .resubmit-button-container {
        section {
          display: flex;
          padding-top: 20px;
          justify-content: center;
          padding-bottom: 0;

          .leshen-link {
            margin: 0 30px;
          }

          @media (max-width: 540px) {
            padding-top: 0;

            .leshen-link {
              margin: 0 20px;
              width: 50%;

              button {
                width: 100%;
              }
            }
          }
        }
      }

      .policy-image {
        margin-right: 20px;

        .gatsby-image-wrapper {
          @media (max-width: 540px) {
            margin: 0 auto;
          }
        }
      }

      .policy-card-container {
        border: 3px solid #c8ccd0;
        display: flex;
        justify-content: center;
        max-width: 600px;
        margin: 30px auto 0;
        padding: 30px 15px;
        border-radius: 16px;

        @media (max-width: 540px) {
          display: block;
          text-align: center;
        }

        strong {
          font-family: 'Averta Bold', sans-serif !important;
        }

        .policy-image {
          .gatsby-image-wrapper {
            width: 150px;
            max-width: 200px;
            max-height: 150px;
          }
        }
        .policy-info {
          p {
            margin: 0;
          }
        }
      }
    `}
  />
))

export default Variable
