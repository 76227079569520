import * as React from 'react'
import { withTheme } from 'emotion-theming'
import { css, Global } from '@emotion/core'

const GlobalCss = withTheme(() => (
  <Global
    styles={css`
      h2 {
        font-weight: bold !important;
      }

      p {
        margin: 0 0 16px 0 !important;
      }

      .breadcrumbs {
        margin-top: unset !important;
      }

      section a {
        color: #000 !important;
        text-decoration: underline;
        font-weight: 700;

        &:hover {
          color: #5a00f5 !important;
          text-decoration: none;
        }
      }

      // TYPOGRAPHY
      .blue-text {
        color: #02168b;

        @media (max-width: 540px) {
          color: #1990ff;
        }
      }

      .white-text {
        color: #fff;
      }

      .text-left-on-small {
        @media (max-width: 540px) {
          text-align: left !important;
        }
      }

      h2 {
        font-size: 40px !important;
        line-height: 48px !important;

        @media (max-width: 768px) {
          font-size: 28px !important;
          line-height: 32px !important;
        }

        &.large {
          font-size: 48px !important;
          line-height: 56px !important;

          @media (max-width: 540px) {
            font-size: 28px !important;
            line-height: 32px !important;
          }
        }
      }

      p {
        &.medium {
          font-size: 18px !important;
          line-height: 24px !important;
        }

        &.large {
          font-size: 28px;
          line-height: 36px;

          @media (max-width: 540px) {
            font-size: 22px;
            line-height: 24px;
          }
        }
      }

      .leshen-list-item {
        margin-bottom: 25px !important;
        font-size: 1rem !important;
      }

      .text-center {
        text-align: center !important;

        @media (max-width: 540px) {
          text-align: left !important;
        }
      }

      .text-center-on-small {
        @media (max-width: 540px) {
          text-align: center !important;
        }
      }

      // PADDING
      .top-bottom-padding-48 {
        padding-top: 48px !important;
        padding-bottom: 48px !important;
      }

      .margin-bottom-5 {
        margin-bottom: -5px;
      }

      .margin-bottom-24 {
        margin-bottom: 24px !important;
      }

      .margin-x-auto {
        margin: 0 auto;
      }

      .padding-bottom-40 {
        padding-bottom: 40px !important;
      }

      .faq-section-padding {
        padding-top: 0 !important;
        padding-bottom: 40px !important;

        .content {
          margin-bottom: 20px !important;
        }
      }

      .heading-small-margin-bottom {
        .content {
          margin-bottom: 20px !important;
        }
      }

      .leshen-accordion {
        .button-group {
          margin-bottom: 16px;
        }

        .leshen-accordion-item {
          margin-bottom: 16px !important;

          .header-text {
            margin-bottom: 0 !important;
          }
        }
      }

      .pink {
        color: #f004b9 !important;
      }

      .gray-bg {
        &.bg-overwrite {
          background-color: #f1f2f3 !important;

          section {
            background-color: #f1f2f3 !important;
          }
        }
      }

      .center-buttons {
        width: 100%;

        .leshen-link {
          display: flex;
          justify-content: center;
        }
      }

      .switch-bg-white-mobile {
        &.overwrite-bg {
          @media (max-width: 940px) {
            background-color: #fff !important;
          }
        }
      }

      .display-inline-mobile {
        @media (max-width: 939px) {
          display: inline-block;
        }
      }

      .leshen-billboard-section-split .leshen-link-button {
        min-width: unset !important;
      }
    `}
  />
))

export default GlobalCss
