import * as React from 'react'
import { withTheme } from 'emotion-theming'
import { css, Global } from '@emotion/core'

const Banner = withTheme(({ theme }) => (
  <Global
    styles={css`
      .brand-logos {
        .content {
          width: 100%;
          margin-bottom: 0;

          h4 {
            color: #4604bd;
            font-size: 28px;
          }
        }

        img {
          max-width: 175px;
          max-height: 100px;
          width: 100%;
        }
      }

      .cta-banner {
        padding: ${theme.spacing.lg} ${theme.spacing.sm} !important;

        div:first-of-type {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-align: center;

          .heading,
          .subheading {
            color: ${theme.colors.light.base.hex};
          }

          .leshen-link-button {
            min-width: 200px;
            margin-left: auto;
            margin-right: auto;
          }
        }
      }
    `}
  />
))

export default Banner
