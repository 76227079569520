import * as React from 'react'
import { withTheme } from 'emotion-theming'
import { css, Global } from '@emotion/core'

const TableCss = withTheme(({ theme }) => (
  <Global
    styles={css`
      .comparison-table {
        margin-bottom: ${theme.spacing.md};

        @media screen and (max-width: 540px) {
          overflow: scroll;
        }

        .table-header {
          background-color: #1b0b54;
          padding: ${theme.spacing.md} ${theme.spacing.sm};
          text-align: center;

          @media screen and (min-width: ${theme.breakpoints.lg}) {
            padding: ${theme.spacing.md} 100px;
          }

          h3 {
            color: #ffffff;
          }
        }

        .table-main {
          border: 2px solid #f1f2f3;
          border-top: 0;
          padding: 0;
          margin: 0;
          border-collapse: collapse;
          width: 100%;

          thead {
            border-top: 1px solid #f1f2f3;

            td {
              padding: ${theme.spacing.md} ${theme.spacing.xs};
              margin: 0;
              border-left: 2px solid #f1f2f3;
              border-right: 2px solid #f1f2f3;
              border-bottom: 2px solid #00a5ff;
              text-align: center;
            }

            td:first-of-type {
              width: 33%;

              @media screen and (min-width: ${theme.breakpoints.lg}) {
                width: 45%;
              }
            }
          }

          tbody {
            tr {
              border-bottom: 2px solid #f1f2f3;

              td {
                border-left: 2px solid #f1f2f3;
                border-right: 2px solid #f1f2f3;
                padding: ${theme.spacing.xs};
                text-align: center;

                img {
                  width: 48px;
                  height: 48px;
                }
              }

              td:first-of-type {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: ${theme.spacing.sm};
                border-left: 0;
                border-right: 0;
                text-align: left;
              }
            }

            tr:last-of-type {
              td {
                border-bottom: 0;
              }
            }
          }
        }

        .table-after {
          text-align: left;

          @media screen and (min-width: ${theme.breakpoints.md}) {
            text-align: center;
          }
        }

        &.bg-white {
          background-color: #ffffff !important;
        }

        &.medigap {
          thead {
            td:first-of-type {
              width: 25%;
            }

            .leshen-typography-h6 {
              margin-bottom: 0 !important;
            }
          }

          tbody {
            tr {
              td:first-of-type {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: flex-start;
                align-items: flex-start;
                gap: 0;

                .leshen-typography-h6 {
                  margin-bottom: 0 !important;
                  width: 100%;
                }

                .leshen-typography-body {
                  margin-bottom: 0 !important;
                }
              }
            }
          }
        }
      }
    `}
  />
))

export default TableCss
