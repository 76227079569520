import * as React from 'react'
import { withTheme } from 'emotion-theming'
import { css, Global } from '@emotion/core'
import blueMountainsBackground from '../../images/backgrounds/blue-mountains.jpg'

const HeroStyles = withTheme(() => (
  <Global
    styles={css`
      .blue-mountains-background {
        background-image: url(${blueMountainsBackground});
        background-size: cover;
        background-position: bottom;
        color: #fff;
      }
    `}
  />
))

export default HeroStyles
